export var CardType;
(function (CardType) {
    CardType[CardType["Spades"] = 0] = "Spades";
    CardType[CardType["Hearts"] = 1] = "Hearts";
    CardType[CardType["Diamonds"] = 2] = "Diamonds";
    CardType[CardType["Clubs"] = 3] = "Clubs";
})(CardType || (CardType = {}));
export var CardValue;
(function (CardValue) {
    CardValue[CardValue["One"] = 1] = "One";
    CardValue[CardValue["Two"] = 2] = "Two";
    CardValue[CardValue["Three"] = 3] = "Three";
    CardValue[CardValue["Four"] = 4] = "Four";
    CardValue[CardValue["Five"] = 5] = "Five";
    CardValue[CardValue["Six"] = 6] = "Six";
    CardValue[CardValue["Seven"] = 7] = "Seven";
    CardValue[CardValue["Eight"] = 8] = "Eight";
    CardValue[CardValue["Nine"] = 9] = "Nine";
    CardValue[CardValue["Ten"] = 10] = "Ten";
    CardValue[CardValue["Jack"] = 11] = "Jack";
    CardValue[CardValue["Knight"] = 12] = "Knight";
    CardValue[CardValue["Queen"] = 13] = "Queen";
    CardValue[CardValue["King"] = 14] = "King";
})(CardValue || (CardValue = {}));
