import config from "../config.json";
export class RoomService {
    static async getRooms() {
        const response = await fetch(config.backendUrl + "/room");
        const rooms = await response.json();
        return rooms;
    }
    static async addRoom(name) {
        if (!name)
            return false;
        const response = await fetch(config.backendUrl + "/room/" + name, {
            method: "POST",
        });
        if (response.status !== 201) {
            alert(await response.text());
        }
        return response.status === 201;
    }
    static async getRoom(roomName, playerName) {
        try {
            const response = await fetch(config.backendUrl + `/room/${roomName}${playerName ? "/" + playerName : ""}`);
            if (response.status !== 200) {
                return;
            }
            const room = await response.json();
            return room;
        }
        catch (err) {
            return undefined;
        }
    }
    static async joinAsPlayer(roomName, playerName) {
        const response = await fetch(config.backendUrl + `/room/${roomName}/play/join`, {
            method: "POST",
            body: JSON.stringify({ playerName }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (response.status !== 200) {
            alert(await response.text());
        }
        return response.status === 200;
    }
    static async leaveAsPlayer(roomName, playerName) {
        const response = await fetch(config.backendUrl + `/room/${roomName}/play/leave`, {
            method: "POST",
            body: JSON.stringify({ playerName }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (response.status !== 200) {
            alert(await response.text());
        }
        return response.status === 200;
    }
    static async startRound(roomName) {
        const response = await fetch(config.backendUrl + `/room/${roomName}/play/start`, {
            method: "POST"
        });
        if (response.status !== 200) {
            alert(await response.text());
        }
    }
    static async playCard(roomName, playerName, cardIndex) {
        const response = await fetch(config.backendUrl + `/room/${roomName}/play/card`, {
            method: "POST",
            body: JSON.stringify({ playerName, cardIndex }),
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (response.status !== 200) {
            alert(await response.text());
        }
        return response.status === 200;
    }
    static async knock(roomName, playerName) {
        const response = await fetch(config.backendUrl + `/room/${roomName}/play/knock`, {
            method: "POST",
            body: JSON.stringify({ playerName }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            alert(await response.text());
        }
        return response.status === 200;
    }
    static async knockEval(roomName, playerName, pass) {
        const response = await fetch(config.backendUrl + `/room/${roomName}/play/knock/eval`, {
            method: "POST",
            body: JSON.stringify({ playerName, pass }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            alert(await response.text());
        }
        return response.status === 200;
    }
}
