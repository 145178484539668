import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { PlayerInput } from "../components/playerInput";
import { ReactObject } from "../models/ReactObject";
import { SimpleGame } from "../models/SimpleGame";
import { ManualList } from "../components/manualList";
import { PromptDialog } from "../components/dialog/PromptDialog";
export const ListPage = () => {
    const game = ReactObject.formState(new SimpleGame());
    const [activeDialog, activeDialogSetter] = useState("");
    const [player, setPlayer] = useState(undefined);
    const resetActiveDialog = () => {
        setPlayer(undefined);
        activeDialogSetter("");
    };
    game.eventEmitter = (event, player) => {
        activeDialogSetter(event);
        setPlayer({ ...player });
    };
    return (_jsxs(_Fragment, { children: [_jsx(PlayerInput, { knownPlayerNames: game.players.map(p => p.name), onNewPlayer: game.addPlayerFromName.bind(game) }), _jsx(ManualList, { game: game }), _jsx(PromptDialog, { title: `🎉 ${player?.name} won the round 🎉`, text: "", onClose: resetActiveDialog, open: activeDialog === "endRound" }), _jsx(PromptDialog, { title: `👑 ${player?.name} is the winner 👑`, text: `${player?.name} won the game with only ${player?.points} points.`, onClose: resetActiveDialog, open: activeDialog === "endGame" })] }));
};
