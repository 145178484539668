import { useState } from "react";
import { ReactArray } from "./ReactArray";
function defaultBehavior(obj, key, value) {
    const [getValue, setValue] = useState(value);
    let currentValue = getValue;
    Object.defineProperty(obj, key, {
        get: () => currentValue,
        set: (v) => { setValue(v); currentValue = v; },
    });
}
function arrayBehavior(obj, key, value) {
    const [getValue, setValue] = useState(new ReactArray(() => console.log("Event not set."), ...value));
    let currentValue = getValue;
    function updateHandler(arr) {
        currentValue = new ReactArray(updateHandler, ...arr);
        setValue(currentValue);
    }
    currentValue.event = updateHandler;
    Object.defineProperty(obj, key, {
        get: () => currentValue,
        set: (v) => {
            currentValue = new ReactArray(updateHandler, ...v);
            setValue(currentValue);
        },
    });
}
export var ReactObject;
(function (ReactObject) {
    function formState(obj, options = {}) {
        let entries = Object.entries(obj);
        if (!options.includeFunctions) {
            entries = entries.filter(([key, value]) => typeof value !== "function");
        }
        const customFilter = options.customFilter;
        if (customFilter && typeof customFilter === "function") {
            entries = entries.filter(([key, value]) => customFilter(key, value));
        }
        entries.forEach(([key, value]) => {
            if (Array.isArray(value)) {
                arrayBehavior(obj, key, value);
            }
            else {
                defaultBehavior(obj, key, value);
            }
        });
        return obj;
    }
    ReactObject.formState = formState;
})(ReactObject || (ReactObject = {}));
