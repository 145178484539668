import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { ReactObject } from "../models/ReactObject";
class PlayerInputState {
    players;
    playerName = "";
    isButtonActive = false;
    constructor(players) {
        this.players = players;
    }
    /**
     * Handle the update event of an input field that is used to fill in the player name.
     *
     * @param event Input change event.
     */
    updatePlayerHandler = (event) => {
        this.updatePlayer(event.target.value);
    };
    /**
     * Update the name of the player.
     *
     * @param name The new version of the player name.
     */
    updatePlayer(name) {
        this.isButtonActive = name !== undefined && name !== "" && !this.players.some(pName => pName === name);
        this.playerName = name;
    }
    /** Attempt to create and add a new player with player name. */
    addPlayer(event) {
        if (!this.isButtonActive) {
            return;
        }
        event(this.playerName);
        this.playerName = "";
        this.isButtonActive = false;
    }
}
export const PlayerInput = (args) => {
    const state = ReactObject.formState(new PlayerInputState(args.knownPlayerNames));
    return _jsxs("div", { children: [_jsx("input", { type: "text", placeholder: "player name", value: state.playerName, onChange: state.updatePlayerHandler, onKeyDown: (event) => { if (event.key === "Enter")
                    state.addPlayer(args.onNewPlayer); } }), _jsx(Button, { variant: "contained", disabled: !state.isButtonActive, onClick: state.addPlayer.bind(state, args.onNewPlayer), children: "Add Player" })] });
};
