import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { KnockStatus } from "../models/SimplePlayer";
import { RemovePlayerDialog } from "./dialog/RemovePlayerDialog";
import { UpdatePlayerPointsDialog } from "./dialog/UpdatePlayerPointsDialog";
export const ManualList = (args) => {
    const { game } = args;
    const [activeDialog, activeDialogSetter] = useState("");
    const [playerName, playerNameSet] = useState(undefined);
    const resetActiveDialog = () => {
        playerNameSet(undefined);
        activeDialogSetter("");
    };
    const getColor = (player) => {
        if (player.points === game.maxPoints)
            return "gray";
        let localPoints = player.points;
        if (player.knockStatus !== KnockStatus.Out) {
            localPoints += game.points;
        }
        if (localPoints === 9) {
            return "rgb(245, 182, 88)";
        }
        else if (localPoints >= 10) {
            return "rgb(245, 119, 88)";
        }
        return undefined;
    };
    return _jsxs(_Fragment, { children: [_jsxs("span", { children: ["Points on loss/pass: ", game.points] }), _jsx("div", { className: "tableDiv", children: _jsx(TableContainer, { children: _jsx(Table, { sx: { scrollBehavior: "auto" }, children: _jsx(TableBody, { children: game.players.map((p) => (_jsxs(TableRow, { style: {
                                    backgroundColor: getColor(p),
                                }, children: [_jsx(TableCell, { children: p.name }), _jsxs(TableCell, { onClick: () => {
                                            activeDialogSetter("update");
                                            playerNameSet(p.name);
                                        }, children: [p.points, "/", game.maxPoints] }), !game.inKnockState() && _jsxs(_Fragment, { children: [_jsx(TableCell, { children: _jsx(Button, { variant: "contained", disabled: p.points + game.points >= game.maxPoints
                                                        || p.knockStatus === KnockStatus.Out
                                                        || game.players.length === 1
                                                        || p.name === game.lastKnockPlayerName, onClick: () => game.knock(p), children: "Knock" }) }), _jsx(TableCell, { children: _jsx(Button, { variant: "contained", color: "success", disabled: p.knockStatus !== KnockStatus.None
                                                        || game.players.length === 1, onClick: () => game.winner(p), children: "Winner" }) })] }), game.inKnockState() && _jsxs(_Fragment, { children: [p.knockStatus === KnockStatus.InWait && _jsxs(_Fragment, { children: [_jsx(TableCell, { children: _jsx(Button, { variant: "contained", onClick: () => {
                                                                p.knockStatus = KnockStatus.Continue;
                                                                game.updateKnock();
                                                                game.players = game.players;
                                                            }, children: "Continue" }) }), _jsx(TableCell, { children: _jsx(Button, { variant: "contained", color: "error", onClick: () => {
                                                                p.knockStatus = KnockStatus.Pass;
                                                                game.updateKnock();
                                                                game.players = game.players;
                                                            }, children: "Pass" }) })] }), p.knockStatus !== KnockStatus.InWait && _jsx(_Fragment, { children: _jsx(TableCell, { colSpan: 2, children: (p.knockStatus === KnockStatus.Continue
                                                        || p.knockStatus === KnockStatus.Pass)
                                                        && p.name !== game.lastKnockPlayerName
                                                        && p.points + game.points < 10 &&
                                                        _jsxs(Button, { variant: "outlined", style: { width: "100%" }, onClick: () => {
                                                                p.knockStatus = KnockStatus.InWait;
                                                                game.updateKnock();
                                                                game.players = game.players;
                                                            }, children: ["Cancel ", p.knockStatus === KnockStatus.Continue ? "continue" : "pass"] }) }) })] }), _jsx(TableCell, { children: _jsx(Button, { variant: "outlined", color: "warning", onClick: () => {
                                                activeDialogSetter("remove");
                                                playerNameSet(p.name);
                                            }, children: "Remove" }) })] }, `${p.name}-manual-list-row`))) }) }) }) }), _jsx(RemovePlayerDialog, { game: game, playerName: playerName ?? "", open: activeDialog == "remove", onClose: resetActiveDialog }), _jsx(UpdatePlayerPointsDialog, { game: game, playerName: playerName ?? "", open: activeDialog == "update", onClose: resetActiveDialog })] });
};
