import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { RoomService } from "../services/RoomService";
import { RoomListItem } from "../components/roomListItem";
import { Button } from "@mui/material";
export const HomePage = () => {
    const [rooms, setRooms] = useState([]);
    const [roomName, setRoomName] = useState("");
    const [isButtonActive, setIsButtonActive] = useState(false);
    const redirectToRoom = (room) => {
        location.href = "/room/" + room.name;
    };
    const inputEventHandler = (event) => {
        const value = event.target.value;
        setIsButtonActive(value !== undefined && value !== "" && !rooms.some(r => r.name === value));
        setRoomName(value);
    };
    const createEventHandler = () => {
        if (!isButtonActive)
            return;
        RoomService.addRoom(roomName)
            .then(res => {
            if (res)
                redirectToRoom({ name: roomName });
        })
            .catch(() => {
            console.log("Unable to create a room without server connection.");
        });
    };
    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const r = await RoomService.getRooms();
                setRooms(r);
            }
            catch (err) { }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    });
    return (_jsxs(_Fragment, { children: [_jsx(Button, { color: "primary", variant: "contained", onClick: () => location.href = "/list", children: "Play list" }), _jsxs("div", { className: "form verticalFlex", children: [_jsxs("div", { children: [_jsx("h3", { children: "Create a new toep-room" }), _jsx("input", { type: "text", placeholder: "Room name", className: "fullWidth", value: roomName, onChange: inputEventHandler, onKeyDown: (event => { if (event.key === "Enter")
                                    createEventHandler(); }) })] }), _jsx(Button, { className: "createButton", color: "primary", variant: "contained", disabled: !isButtonActive, onClick: createEventHandler, children: "Create" })] }), _jsx("div", { className: "roomList", children: rooms.map(room => _jsx(RoomListItem, { room: room, onClick: redirectToRoom })) })] }));
};
