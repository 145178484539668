export class ReactArray extends Array {
    event;
    constructor(event, ...args) {
        super(...args);
        this.event = event;
    }
    push(...items) {
        const result = super.push(...items);
        this.event(this);
        return result;
    }
    pop() {
        const result = super.pop();
        this.event(this);
        return result;
    }
    shift() {
        const result = super.shift();
        this.event(this);
        return result;
    }
    unshift(...items) {
        const result = super.unshift();
        this.event(this);
        return result;
    }
    // @ts-expect-error Reasons.
    splice(start, deleteCount, ...rest) {
        // @ts-expect-error Reasons.
        const result = super.splice(start, deleteCount, ...rest);
        this.event(this);
        return result;
    }
    fill(value, start, end) {
        const result = super.fill(value, start, end);
        this.event(this);
        return result;
    }
    copyWithin(target, start, end) {
        const result = super.copyWithin(target, start, end);
        this.event(this);
        return result;
    }
}
