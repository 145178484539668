import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { KnockStatus } from "../../models/SimplePlayer";
import { Button, ButtonGroup, Dialog, DialogContent, DialogTitle } from "@mui/material";
export const UpdatePlayerPointsDialog = (args) => {
    const player = args.game.players.find((p) => p.name === args.playerName);
    if (!player) {
        console.error(`No player found with name ${args.playerName}`);
        args.onClose();
        return;
    }
    const [points, setPoints] = useState(player.points);
    const setChangedValue = () => {
        const prevPoints = player.points;
        const prevState = player.knockStatus;
        // Update point total
        player.points = points ?? 0;
        // Update player state
        if (prevState !== KnockStatus.Out
            || (prevState === KnockStatus.Out && prevPoints >= args.game.maxPoints)) {
            player.knockStatus = args.game.inKnockState()
                ? KnockStatus.InWait
                : KnockStatus.None;
        }
        if (player.points >= args.game.maxPoints) {
            player.knockStatus = KnockStatus.Out;
            args.game.checkWinner();
        }
        if (args.game.lastKnockPlayerName === undefined
            && args.game.points === 1
            && prevPoints === (args.game.maxPoints - 1)) {
            args.game.cancelKnock();
            args.game.checkPoverty();
        }
        args.game.players = args.game.players;
        args.onClose();
    };
    return (_jsxs(Dialog, { open: args.open ?? false, onClose: args.onClose, PaperProps: { style: { backgroundColor: "antiquewhite" } }, children: [_jsxs(DialogTitle, { align: "center", children: ["Update point total of ", args.playerName] }), _jsxs(DialogContent, { children: [_jsx("input", { type: "number", min: 0, max: args.game.maxPoints, value: points, style: {
                            width: "100%",
                        }, autoFocus: true, onChange: (event) => {
                            setPoints(Math.max(0, Math.min(Number.parseInt(event.target.value), args.game.maxPoints)));
                        }, onKeyDown: (event) => {
                            if (event.key === "Enter") {
                                setChangedValue();
                            }
                        } }), _jsxs(ButtonGroup, { fullWidth: true, children: [_jsx(Button, { color: "primary", variant: "contained", onClick: args.onClose, children: "Cancel" }), _jsx(Button, { color: "warning", variant: "outlined", onClick: args.onClose, children: "Update" })] })] })] }));
};
