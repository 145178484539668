import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RoomService } from "../services/RoomService";
import { Error404 } from "./404";
import { PlayerKnockStatus } from "../models/player";
import { PlayingCard } from "../components/card";
import { Button } from "@mui/material";
export const RoomPage = () => {
    const { roomName } = useParams();
    if (!roomName) {
        return Error404({});
    }
    const [notification] = useState(new Audio("/sounds/notification.wav"));
    const [room, setRoom] = useState({});
    const [playerName, setPlayerName] = useState(sessionStorage.getItem(roomName));
    const [canJoin, setCanJoin] = useState(false);
    const [inGame, setInGame] = useState(false);
    const [promptBox, setPromptBox] = useState(undefined);
    const [selectedCard, setSelectedCard] = useState(undefined);
    const [inputState, setInputState] = useState(false);
    const [lastCurrentPlayerName, setLastCurrentPlayerName] = useState("");
    const updatePlayerName = (event) => {
        const value = event.target.value;
        setPlayerName(value);
        setCanJoin(value !== undefined && value !== "" && !room?.players.map(p => p.name).includes(value));
    };
    const joinGame = () => {
        if (!playerName || !canJoin) {
            return;
        }
        RoomService.joinAsPlayer(roomName, playerName)
            .then(response => {
            if (response) {
                console.log("Joined as player: " + playerName);
                setInGame(true);
                sessionStorage.setItem(roomName, playerName);
            }
        });
    };
    const leaveGame = () => {
        setPromptBox(_jsxs(_Fragment, { children: [_jsx("h4", { style: { display: "block", width: "auto" }, children: "Are you sure?" }), _jsxs("div", { className: "verticalFlex", children: [_jsx(Button, { color: "primary", variant: "contained", onClick: () => {
                                setPromptBox(undefined);
                            }, children: "No" }), _jsx(Button, { color: "primary", variant: "outlined", onClick: () => {
                                RoomService.leaveAsPlayer(roomName, playerName ?? "")
                                    .then((response) => {
                                    if (response) {
                                        setPromptBox(undefined);
                                        setPlayerName(undefined);
                                        setInGame(false);
                                        sessionStorage.clear();
                                    }
                                });
                            }, children: "Yes" })] })] }));
    };
    const startGame = () => {
        console.log("Start round.");
        RoomService.startRound(roomName);
    };
    const playCard = () => {
        if (selectedCard === undefined || playerName === undefined) {
            return;
        }
        RoomService.playCard(roomName, playerName, selectedCard.index)
            .then((response) => {
            if (response) {
                setInputState(false);
            }
        });
    };
    const knock = () => {
        if (playerName === undefined) {
            return;
        }
        RoomService.knock(roomName, playerName);
    };
    const respondToKnock = (pass) => {
        if (playerName === undefined) {
            return;
        }
        RoomService.knockEval(roomName, playerName, pass);
    };
    useEffect(() => {
        const interval = setInterval(async () => {
            const r = await RoomService.getRoom(roomName, inGame ? playerName : undefined);
            if (!r) {
                console.log("Unable to fetch room " + roomName);
                location.href = "/";
            }
            else {
                setRoom(r);
                const name = room?.inRound ? r.currentPlayer?.name ?? "" : "";
                if (inGame && name !== lastCurrentPlayerName && name === playerName) {
                    notification.play();
                }
                setLastCurrentPlayerName(name);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    });
    const getColor = (player) => {
        if (player.points === room?.settings?.maxPoints)
            return "gray";
        let localPoints = player.points;
        if (player.knockStatus !== PlayerKnockStatus.Out) {
            localPoints += room?.pointsForLoss ?? 1;
        }
        if (localPoints === 9) {
            return "rgb(245, 182, 88)";
        }
        else if (localPoints >= 10) {
            return "rgb(245, 119, 88)";
        }
        return undefined;
    };
    const realMod = (value, mod) => {
        const result = value % mod;
        if (result < 0) {
            return Math.abs(mod) + result;
        }
        return result;
    };
    return (_jsxs(_Fragment, { children: [_jsx("h2", { style: { margin: 0 }, children: roomName }), !inGame && _jsxs("div", { className: "form verticalFlex", children: [_jsx("input", { type: "text", placeholder: "Your player name.", onChange: updatePlayerName, onKeyDown: (event) => { if (event.key === "Enter")
                            joinGame(); } }), _jsx(Button, { color: "primary", variant: "contained", disabled: !canJoin, onClick: joinGame, children: "Join" })] }), inGame && _jsxs("div", { children: [_jsx(Button, { color: "primary", variant: "contained", disabled: room?.inRound || ((room?.players.length ?? 2) < 2), onClick: startGame, children: "Start Round" }), _jsx(Button, { color: "warning", variant: "contained", onClick: leaveGame, children: "Leave" })] }), _jsx("div", { className: "tableDiv", children: _jsx("table", { children: _jsx("tbody", { children: room && room.players?.map((p, i) => _jsxs("tr", { style: {
                                backgroundColor: getColor(p),
                            }, children: [_jsx("td", { children: p.name }), _jsx("td", { children: `${p.points}/${room.settings?.maxPoints}` })] }, i)) }) }) }), _jsx("div", { className: "tableDiv", children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Points" }), _jsx("th", { children: "R1" }), _jsx("th", { children: "R2" }), _jsx("th", { children: "R3" }), _jsx("th", { children: "R4" })] }) }), _jsx("tbody", { children: room?.players?.map((p, i) => _jsx(_Fragment, { children: _jsxs("tr", { children: [_jsxs("td", { children: [p.name, " ", p.name === room.winner?.name ? "👑" : "", " ", _jsx("span", { children: p.name === room.currentPlayer?.name ? "•" : "" })] }), _jsx("td", { children: `${p.points}/${room.settings.maxPoints}` }), (p.cards?.sort((c1, c2) => realMod(c1.isPlayedInRound - 1, 100) - realMod(c2.isPlayedInRound - 1, 100)) ?? [])
                                            .map(c => _jsx("td", { children: _jsx("div", { className: "card", children: _jsx(PlayingCard, { cardType: c?.type, cardValue: c?.value }) }) }))] }, i) })) })] }) }), playerName !== undefined && room?.players?.some((p) => p.name === playerName) && _jsxs("div", { className: "openHand", onClick: () => setInputState(true), children: [_jsx("span", { children: "\u270B" }), room.currentPlayer?.name == playerName && _jsx("div", {})] }), (inputState || room?.isInKnockState) && _jsx("div", { className: "valueChanger", onClick: () => setInputState(false), children: _jsxs("div", { onClick: (event) => event.stopPropagation(), children: [_jsx("div", { className: "close", onClick: () => setInputState(false), children: "\u2715" }), !room?.isInKnockState && room?.inRound && _jsxs(_Fragment, { children: [_jsxs("div", { className: "cardList", children: [_jsx("div", { className: "points", children: `${room?.players.find(p => p.name === playerName)?.points ?? 0}/${room.settings?.maxPoints}` }), room?.players.find(p => p.name === playerName)
                                            ?.cards
                                            ?.filter(c => c.isPlayedInRound === 0)
                                            .map((c, i, arr) => _jsxs("div", { className: "card", onClick: () => {
                                                if (c === selectedCard) {
                                                    setSelectedCard(undefined);
                                                }
                                                else {
                                                    setSelectedCard(c);
                                                }
                                            }, children: [_jsx(PlayingCard, { cardType: c.type, cardValue: c.value }), selectedCard?.index !== c.index && _jsx("div", {}), room.firstCardOfRound !== undefined && c.type !== room.firstCardOfRound.type && arr.some(card => card.type === room.firstCardOfRound?.type) &&
                                                    _jsx("div", { style: { border: "2px solid red" } })] }))] }), _jsx(Button, { color: "primary", variant: "contained", style: { width: "100%" }, onClick: playCard, disabled: selectedCard === undefined || room?.currentPlayer?.name !== playerName, children: "Play card" }), _jsx("hr", {}), _jsx(Button, { color: "primary", variant: "contained", onClick: knock, children: "Knock" })] }), room?.isInKnockState && room.players.find((p) => p.name === playerName)?.knockStatus === PlayerKnockStatus.Deciding && _jsxs(_Fragment, { children: [_jsxs("h4", { children: [room.lastKnockPlayer?.name, " knocked"] }), _jsx(Button, { color: "primary", variant: "contained", onClick: () => respondToKnock(false), children: "Continue" }), _jsx(Button, { color: "primary", variant: "contained", onClick: () => respondToKnock(true), children: "Pass" })] }), room?.isInKnockState && room.players.find((p) => p.name === playerName)?.knockStatus !== PlayerKnockStatus.Deciding && _jsx(_Fragment, { children: _jsx("h4", { children: "Waiting on other players." }) })] }) }), promptBox !== undefined && _jsx("div", { className: "valueChanger", children: _jsx("div", { children: promptBox }) })] }));
};
